@font-face {
    font-family: 'Pnt';
    src: url('../../static/fonts/pnt/pnt-bld.woff2') format('woff2'),
        url('../../static/fonts/pnt/pnt-bld.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'Pnt';
    src: url('../../static/fonts/pnt/pnt.woff2') format('woff2'),
        url('../../static/fonts/pnt/pnt.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}