.mega-menu 
    display: none
    left: 0
    position: absolute
    text-align: left
    width: 100%

.hoverable 
    position: static
  
.hoverable:hover .mega-menu 
    display: block
  
