// @import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500;700&display=swap')
@import url('./../../static/fonts/pnt/fonts.css')
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap')

@tailwind base
@tailwind components
@tailwind utilities

@import components/megamenu



* 
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale


.scrollbar-hidden::-webkit-scrollbar
	display: none

.line-clamp 
	display: -webkit-box
	-webkit-line-clamp: 3
	-webkit-box-orient: vertical 

.skeleton-loader
	.skeleton-loader-gradient
		animation-name: skeletonLoaderAnimation
		animation-duration: 1.2s
		animation-timing-function: linear
		animation-iteration-count: infinite
		animation-fill-mode: forwards



@keyframes skeletonLoaderAnimation
	0%
		left: 0%
		transform: translateX(-100%)

	100%

		left: 100%
		transform: translateX(100%)

.scrollable-tabs
	scroll-behavior: smooth


